header {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, .7) 10%, transparent 90%);
    transition: all ease 0.5s;
}

header.black {
    background-color: rgb(160 13 68);
    display: flex !important;
}

.header--logo {
    height: 40px;
}

.header--logo img {
    height: 100%;
}

.header--user {
    height: 35px;
}

.header--user img {
    height: 100%;
    border-radius: 2px;
}

.header--social a {
    padding: 5px 15px;
    color: #fff;
    text-decoration: none;
}

