@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

* {
  box-sizing: border-box;
}

body {
  background-color: #cc084a;
  color: #fff;
  margin: 0;
  font-family: 'Poppins', sans-serif;
}

.lists {
  margin-top: -150px;
  position: relative;
  z-index: 1;
}

footer {
  margin: 50px 0;
  text-align: center;
}

.loading {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #cc084a;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}

a.link {
  text-decoration: none !important;
  color: #e2e2e2;
}

a.link.active {
  color: #ffffff;
}

