#sepp {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 1;
    animation: slideIn 4s forwards;   
}

#sepp img {
    max-width: 8vw;
}

@keyframes slideIn {
    0% {
        transform: translateX(100%);
    }

    10% {
        transform: translateX(0);
    }

    95% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(100%);
    }
}