.movieRow {
    margin-bottom: 30px;
}

.movieRow h2 {
    font-size: 1.4vw;
    font-weight: 400;
    margin: 0px 0px 0px 30px;
}

.movieRow--listarea {
    overflow-x: hidden;
    padding-left: 30px;

}

.movieRow--list {
    transition: all ease 0.5s;
}

.movieRow--item {
    display: inline-block;
    width: 230px;
    cursor: pointer;
    transform: scale(0.9);
    transition: all ease 0.2s;
    position: relative;
}

.movieRow--overlay {
    background-color: #000000b7;
    padding: 10px;
    text-align: center;
    display: none;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 8px;
    background-position: center;
    background-size: cover;

    transition: all ease 0.2s;
    color: #FFFFFF;
}

.movieRow--overlay h3 {
    margin: 0;
}

.movieRow--item img {
    width: 100%;
    border-radius: 8px;

}

.movieRow--item:hover {
    transform: scale(1);
}

.movieRow--item:hover .movieRow--overlay {
    display: flex;
}

.movieRow--left,
.movieRow--right {
    position: absolute;
    width: 40px;
    height: 350px;
    /* background-color: rgba(0, 0, 0, 0.6); */
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    cursor: pointer;
    opacity: 0;
    transition: all ease 0.1s;
}

.movieRow--left {
    left: 0;
}

.movieRow--right {
    right: 0
}

.movieRow:hover .movieRow--left,
.movieRow:hover .movieRow--right {
    opacity: 1;
}

@media (max-width:760px) {

    .movieRow--left,
    .movieRow--right {
        opacity: 1;
    }
}